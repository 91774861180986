import { burger } from "./modules/header/burger.js";
import { modals } from "./modules/modals.js";
import { review } from "./modules/review.js";
import { sub } from "./modules/sub.js";
import { carusel } from "./modules/carusel.js";
import { price } from "./modules/price.js";
import { keisu } from "./modules/keisu.js";
import { popup } from "./modules/popup.js";
import { gallery } from "./modules/gallery.js";
document.addEventListener("DOMContentLoaded", function (event) {
  burger();
  review();
  carusel();
  gallery();
  modals();
  sub();
  popup();
  keisu();
  price();
});
