export const review = () => {
  var swiper = new Swiper(".reviews-slider", {
    slidesPerView: 1,
    spaceBetween: 100,
    autoplay: true,
    autoplay: {
      delay: 3500,
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
};
