export const keisu = () => {
  const tabs = document.querySelectorAll(".category_work_tabs a");
  const tabContents = document.querySelectorAll(".category_work_content");

  if (tabs.length === 0 || tabContents.length === 0) {
    console.error("Tabs or tab contents not found");
    return;
  }

  // Активувати перший таб та відповідний вміст
  tabs[0].classList.add("active");
  tabContents[0].classList.add("active");

  tabs.forEach((tab) => {
    tab.addEventListener("click", function (e) {
      e.preventDefault();
      const targetId = this.getAttribute("href").substring(1);
      tabContents.forEach((content) => {
        if (content.id === targetId) {
          content.classList.add("active");
        } else {
          content.classList.remove("active");
        }
      });

      // Встановити активний клас для aтаба
      tabs.forEach((tab) => {
        tab.classList.remove("active");
      });
      this.classList.add("active");
    });
  });
};
