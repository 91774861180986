export const price = () => {
  const tabs = document.querySelectorAll(".price_category_tabs a");
  const tabContents = document.querySelectorAll(".price_category_content");

  // Активувати перший таб та відповідний вміст
  tabs[0].classList.add("active");
  tabContents[0].classList.add("active");

  tabs.forEach((tab) => {
    tab.addEventListener("click", function (e) {
      e.preventDefault();
      const targetId = this.getAttribute("href").substring(1);
      tabContents.forEach((content) => {
        if (content.id === targetId) {
          content.classList.add("active");
        } else {
          content.classList.remove("active");
        }
      });
      tabs.forEach((tab) => {
        tab.classList.remove("active");
      });
      this.classList.add("active");
    });
  });
};
