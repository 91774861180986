export const sub = () => {
  $(document).ready(function () {
    $(".menu-item-has-children > a").click(function (e) {
      e.preventDefault();
      $(this).parent().toggleClass("active");
    });

    $(document).click(function (e) {
      if (!$(e.target).closest(".menu-item-has-children").length) {
        $(".menu-item-has-children").removeClass("active");
      }
    });
  });
};
